<template>
  <b-row align-h="center" style="margin-top: 5em">
    <b-col lg="8" cols="11">
      <h4>The page you are looking for does not exist</h4>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'NotFoundComponent'
}
</script>
