var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5"},[_c('b-row',{staticClass:"mt-5 mb-2",attrs:{"align-h":"center"}},[_c('p',{staticStyle:{"font-size":"24px"}},[_vm._v("Register an Account")])]),_vm._v(" "),_c('b-row',{staticClass:"my-5",attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"lg":"4","sm":"8"}},[_c('b-alert',{staticStyle:{"font-size":"13px"},attrs:{"variant":"danger"},model:{value:(_vm.errorShow),callback:function ($$v) {_vm.errorShow=$$v},expression:"errorShow"}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_vm._v(" "),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.errorMessage))])],1),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("First Name")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"autofocus":"","state":errors[0] ? false : null},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Last Name")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})],1)]}}],null,true)}),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Company"}},[_c('b-input',{model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Email")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"type":"email","state":errors[0] ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required|min:6","name":"Password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Password")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")]),_c('small',{staticClass:"text-muted pl-3"},[_vm._v("(minimum 6 characters)")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"type":"password","state":errors[0] ? false : null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required|confirmed:password","name":"Password Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Password Confirmation")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"type":"password","state":errors[0] ? false : null},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.submit)}},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})],1)]}}],null,true)}),_vm._v(" "),_c('Button',{attrs:{"variant":"blue"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v("Submit")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }