var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAdmin)?_c('div',[_c('b-row',{staticClass:"pt-2 px-4 mt-5 mb-2",attrs:{"align-h":"between"}},[_c('div'),_vm._v(" "),_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v("Manage Brands")]),_vm._v(" "),_c('Button',{attrs:{"variant":"green"},on:{"click":function($event){_vm.newModalShow = true}}},[_vm._v("Add Brand")])],1),_vm._v(" "),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-table',{staticStyle:{"font-size":"14px","max-width":"800px"},attrs:{"fields":_vm.brandFields,"items":_vm.brands,"head-variant":"light","bordered":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('Button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.openEditModal(data.item)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'edit'],"fixed-width":""}}),_vm._v(" "),_c('span',{staticClass:"pl-1"},[_vm._v("Edit")])],1)]}}],null,false,341779279)})],1),_vm._v(" "),_c('b-modal',{attrs:{"title":"Add brand","centered":"","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('span')]},proxy:true}],null,false,1551464711),model:{value:(_vm.newModalShow),callback:function ($$v) {_vm.newModalShow=$$v},expression:"newModalShow"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{attrs:{"align-h":"center"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name (English)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticStyle:{"width":"300px"},attrs:{"label-size":"sm","invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Name (English)")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"autofocus":"","size":"sm","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.brandForm.name_en),callback:function ($$v) {_vm.$set(_vm.brandForm, "name_en", $$v)},expression:"brandForm.name_en"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-form-group',{staticStyle:{"width":"300px"},attrs:{"label":"Name (Chinese)","label-size":"sm"}},[_c('b-input',{attrs:{"size":"sm","state":_vm.brandForm.name_zh ? true : null},model:{value:(_vm.brandForm.name_zh),callback:function ($$v) {_vm.$set(_vm.brandForm, "name_zh", $$v)},expression:"brandForm.name_zh"}})],1)],1),_vm._v(" "),_c('b-row',{staticClass:"mt-3",attrs:{"align-h":"around"}},[_c('Button',{on:{"click":function($event){_vm.newModalShow = false; _vm.clearForm()}}},[_vm._v("Cancel")]),_vm._v(" "),_c('Button',{staticClass:"float-right",attrs:{"variant":"green","disabled":invalid},on:{"click":_vm.createBrand}},[_vm._v("Save")])],1)]}}],null,false,4246350614)})],1),_vm._v(" "),_c('b-modal',{attrs:{"title":"Edit brand","centered":"","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [(_vm.selectedBrand)?_c('span',[_vm._v("Edit brand: "+_vm._s(_vm.selectedBrand.name_en))]):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(){return [_c('span')]},proxy:true}],null,false,1761481303),model:{value:(_vm.editModalShow),callback:function ($$v) {_vm.editModalShow=$$v},expression:"editModalShow"}},[_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{attrs:{"align-h":"center"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name (English)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticStyle:{"width":"300px"},attrs:{"label-size":"sm","invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Name (English)")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"size":"sm","state":errors[0] ? false : null},model:{value:(_vm.brandForm.name_en),callback:function ($$v) {_vm.$set(_vm.brandForm, "name_en", $$v)},expression:"brandForm.name_en"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-form-group',{staticStyle:{"width":"300px"},attrs:{"label":"Name (Chinese)","label-size":"sm"}},[_c('b-input',{attrs:{"size":"sm"},model:{value:(_vm.brandForm.name_zh),callback:function ($$v) {_vm.$set(_vm.brandForm, "name_zh", $$v)},expression:"brandForm.name_zh"}})],1)],1),_vm._v(" "),_c('b-row',{staticClass:"mt-3",attrs:{"align-h":"around"}},[_c('Button',{on:{"click":function($event){_vm.editModalShow = false; _vm.clearForm()}}},[_vm._v("Cancel")]),_vm._v(" "),_c('Button',{staticClass:"float-right",attrs:{"variant":"green","disabled":invalid},on:{"click":_vm.updateBrand}},[_vm._v("Save")])],1)]}}],null,false,1539262984)})],1),_vm._v(" "),_c('ToastAlert',{attrs:{"show":_vm.alertShow,"variant":_vm.alertVariant},on:{"close":function($event){_vm.alertShow = false}}},[_vm._v("\n    "+_vm._s(_vm.alertMessage)+"\n  ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }