<template>
  <b-row align-h="center">
    <h3>About Us</h3>
  </b-row>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped>

</style>

