var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-5 mb-2",attrs:{"align-h":"center"}},[_c('p',{staticStyle:{"font-size":"24px"}},[_vm._v("Set a New Password")])]),_vm._v(" "),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"lg":"4","sm":"8"}},[_c('small',{staticClass:"font-italic text-info"},[_vm._v("The new password and confimation password must match")])])],1),_vm._v(" "),_c('b-row',{staticClass:"mt-3",attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"lg":"4","sm":"8"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"rules":"required","name":"Password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("New Password")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"type":"password","autofocus":"","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","name":"Password Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Password Confirmation")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"type":"password","state":errors[0] ? false : (valid ? true : null)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updatePassword($event)}},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})],1)]}}],null,true)}),_vm._v(" "),_c('b-form-group',[_c('Button',{attrs:{"variant":"blue","disabled":invalid},on:{"click":_vm.updatePassword}},[_vm._v("Save password")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }